import React from "react"
import Layout from "../../components/Layout"
import GDPRcomponent from "../../components/GDPR"
import SEO from "../../components/seo"

export default function GDPR() {
  return (
    <Layout headerType="business">
      <SEO title="Compulease · GDPR Business" />
      <GDPRcomponent />
    </Layout>
  )
}
